import React from "react"

import fight from '../images/leaderIcon_col0.png';
import fight1 from '../images/leaderIcon_col1.png';
import wood from '../images/leaderIcon_wood.png';
import fish from '../images/leaderIcon_fish.png';
import catching from '../images/leaderIcon_catch.png';

const VAL = {
    "FamValColosseumHighscores": {
        name: "Dewdrop Colosseum",
        img: fight,
    },
    "FamValColosseumHighscores_1": {
        name: "Sandstone Colosseum",
        img: fight1,
    },
    
    "FamValMinigameHiscores_0": {
        name: "Woodcutting Minigame",
        img: wood,
    },
    
    "FamValMinigameHiscores_1": {
        name: "Fishing Minigame",
        img: fish,
    },
    
    "FamValMinigameHiscores_2": {
        name: "Catching Minigame",
        img: catching,
    },
    "Lv0_0": {
        name: "Main Level",
        img: fight1,
    },
    "Lv0_1": {
        name: "Mining",
        img: fish,
    },
    "Lv0_2": {
        name: "Catching",
        img: catching,
    },
}

function getIconUrl(icon) {
    if (icon === -1 ||typeof icon !== "number") {
        return "/hats/EquipmentTransparent1.png"
    }
    return "/hats/EquipmentHats"+icon+".png";
}

export default function LeaderboardEntry(props) {

    let data = VAL[props.type]

    return (<div>
        <div className="leader_title">
        <h2>
            {data.name}
        </h2>
        <img src={data.img}/>
        </div>
        <div>
            {props.list.slice(0,5).map((item)=>(<div className="leader_entry" key={item.id}>
                <img className="leader_icon" src={getIconUrl(item.icon)} alt=""/>
                <p className="leader_name">{item.id}</p>
                <p className="leader_score">{item.score.toFixed(0)}</p>
            </div>))}
        </div>
    </div>
    )
}
