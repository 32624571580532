import React from "react"
import { Helmet } from "react-helmet"
import LeaderboardEntry from "../components/leaderboardEntry";
import TopBar from "../components/topBar";

import '../styles/App.scss';
import '../styles/Lead.scss';
import '../styles/Other.scss';

import logo from '../images/Leaderboard.png';
 import bg10 from '../images/Bg10a.png';
import cloud1 from '../images/cloud1.png';
import cloud2 from '../images/cloud2.png';
import rightBg from '../images/Icons_Abilities3c.png';
import BotBar from "../components/BotBar";

export default class Leaderboard extends React.Component  {
  oReq;

  constructor(props) {
    super(props);

    this.state = {
      leaderboards: null
    }

    if (typeof window !== `undefined`) {
      this.oReq = new XMLHttpRequest();
      this.oReq.addEventListener("load", ()=>this.reqListener());
      this.oReq.open("GET", "https://storage.googleapis.com/idlemmo.appspot.com/leaderboards.json");
      this.oReq.setRequestHeader('Access-Control-Allow-Origin', '*');
      this.oReq.send();
    }
  }

  reqListener () {
    let leaderboards = JSON.parse(this.oReq.responseText);
    console.log(leaderboards);
    this.setState({leaderboards})
  }

  render() {
    const seo = {
      title: "Leaderboards | Legends of Idleon",
      description: "Checkout the top players on Legends of Idleon!",
      image: "https://www.LegendsOfIdleon.com/share.png",
      url: "https://www.LegendsOfIdleon.com/leaderboards",
    }

    let leaderboards = this.state.leaderboards;
    let leaderUI;
    if (leaderboards) {
      leaderUI = <div>
        <LeaderboardEntry type="FamValColosseumHighscores" list={leaderboards["FamValColosseumHighscores"][1]} key="fch0"/>
        <LeaderboardEntry type="FamValColosseumHighscores_1" list={leaderboards["FamValColosseumHighscores"][2]} key="fch1"/>
        <LeaderboardEntry type="FamValMinigameHiscores_0" list={leaderboards["FamValMinigameHiscores"][0]} key="fmh0"/>
        <LeaderboardEntry type="FamValMinigameHiscores_1" list={leaderboards["FamValMinigameHiscores"][1]} key="fmh1"/>
        <LeaderboardEntry type="FamValMinigameHiscores_2" list={leaderboards["FamValMinigameHiscores"][2]} key="fmh2"/>
        {/* <LeaderboardEntry type="Lv0_0" list={leaderboards["Lv0"][0]} />
        <LeaderboardEntry type="Lv0_1" list={leaderboards["Lv0"][1]} />
        <LeaderboardEntry type="Lv0_2" list={leaderboards["Lv0"][2]} /> */}
      </div>
    }


    return (
      <div className="AppContainer" style={{ backgroundImage: `url(${rightBg})` }}>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          <meta property="og:url" content={seo.url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1024" />
          <meta property="og:image:height" content="500" />
          <meta property="og:image:alt" content="Play Legends of Idleon" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />

              
          <link rel="icon" type="image/png" href="/favicon64.png" />
          <link rel="apple-touch-icon" href="/logo192_a.png" />
          <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap" rel="stylesheet"></link>
                
          {/*TODO <script type="application/ld+json">
            {`
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "url": "https://www.LegendsOfIdleon.com",
                  "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                  "name": "Legends of Idleon",
                }
              `}
          </script> */}
        </Helmet>
        <div className="LeftGradient" />
        <div className="RightGradient" />
        <div className="App">
          <div className="Content2" style={{backgroundImage: `url(${bg10})`}}>
            <TopBar selected="leaderboards"/>
            
            <div className="RealContent" style={{backgroundColor: "#ab4d3b"}}>

            <header className="">
                <img src={logo} className="Pixel ImageTitle" alt="logo" />
                {/* <img src={card} className="Pixel App-logo" alt="logo" /> */}
                <div>
                  {leaderUI}
                </div>
              </header>
            </div>

            <BotBar/>
          </div>
        </div>
      </div>
    );
  }

}